import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import DateField from '../../form-builder/fields/date';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import ClientField from '../../form-builder/fields/client';
import ArticleField from '../../form-builder/fields/article';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            items: [],
            reservation: false,
            clients: [],
            articlesSearch: [],
            itemsClients: [],
            saldo: 0,
        }
    }

    componentDidMount() {
        // this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    report = async () => {
        let date = new Date().getTime()

        this.updateMultipleParams([
            { name: 'clientCode', value: this.state.filterClientCode },
            { name: 'manufacturer', value: this.state.filterManufacturer },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'page', value: 0 },
            { name: 'timestamp', value: date },
        ])
        this.setState({
            reservation: false,
        })

    }

    clearFilters = () => {
        this.setState({
            filterClientCode: '',
            filterManufacturer: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterArticleFrom: '',

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'clientCode', value: null },
                    { name: 'manufacturer', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'articleFrom', value: null },

                ])

        })
    }
    changeState = (key) => {
        this.state[key] = false;
    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        this.setState({ articlesSearch: articleList.articlesSearch })
    }

    print = async () => {
        this.setState({ loading: true })
        let data = {
            clientCode: this.state.filterClientCode,
            manufacturer: this.state.filterManufacturer,
            dateFrom: this.state.filterDateFrom,
            dateTo: this.state.filterDateTo,
            article: this.state.filterArticleFrom
        }

        let link = await this._apiAsync('print', data)
        console.log(link);
        if (typeof window != 'undefined' && link && link.link) {
            // console.log(link.link);
            window.open(env.API_URL + link.link)
            this.setState({ loading: false })
        }
    }


    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet po fond cijenama</h1>
                    <div className="title-options-group">
                        <button disabled={params ? false : true} onClick={
                            this.print
                        }>
                            <div className={params ? "option blue" : "option disabled"}>
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button disabled={this.state.filterDateFrom && this.state.filterDateTo ? false : true} onClick={() => {
                            this.setState({ exportTable: true })
                        }
                            // this.export
                        }>
                            <div className={this.state.filterDateFrom && this.state.filterDateTo ? "option blue" : "option disabled"}>
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        {/* <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button> */}
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap fund-prices-list" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ display: 'flex' }}>
                                            <div>
                                                {/* <SelectWithSearch label="Šifra komitenta" value={this.state.filterClientCode != null ? this.state.filterClientCode : params.clientCode} onChange={(e) => this.setState({ filterClientCode: e }, async () => {

                                                    const clients = await this._apiAsync('get-clients', { filter: this.state.filterClientCode })
                                                    if (clients && clients.itemsClients) {
                                                        this.setState({ clients: clients && clients.itemsClients }, () => {
                                                            if (this.state.clients.filter(item => item.code == this.state.filterClientCode).length) {
                                                                this.setState({
                                                                    filterClientName: this.state.clients.filter(item => item.code == this.state.filterClientCode)[0].name
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    filterClientName: ''
                                                                })
                                                            }
                                                        })
                                                    }
                                                })} values={[...this.state.clients.map(item => {
                                                    let obj = {
                                                        name: item.name,
                                                        value: item.code
                                                    }
                                                    return obj
                                                })]} /> */}
                                                <ClientField
                                                    label={'Šifra komitenta'}
                                                    metoda={(value) => { this.metodaClient(value) }}
                                                    dataModal={this.state.clientsSearch}
                                                    finishKey={'Enter'}
                                                    onChange={(e) => this.setState({ filterClientCode: e }, async () => {

                                                        const clients = await this._apiAsync('get-clients', { filter: this.state.filterClientCode })
                                                        if (clients && clients.itemsClients) {
                                                            this.setState({ clients: clients && clients.itemsClients }, () => {
                                                                if (this.state.clients.filter(item => item.code == this.state.filterClientCode).length) {
                                                                    this.setState({
                                                                        filterClientName: this.state.clients.filter(item => item.code == this.state.filterClientCode)[0].name
                                                                    })

                                                                } else {
                                                                    this.setState({
                                                                        filterClientName: ''
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })}
                                                    value={this.state.filterClientCode}
                                                // values={[...this.state.clients.map(item => {
                                                //     let obj = {
                                                //         name: item.name,
                                                //         value: item.code
                                                //     }
                                                //     return obj
                                                // })]}
                                                />
                                            </div>
                                            <div>
                                                <Label>Naziv komitenta</Label>
                                                <Input disabled style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} type='text' value={this.state.filterClientName != null ? this.state.filterClientName : ''} />
                                            </div>
                                        </div>

                                        <div className="field-strap-wrap">

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                })}
                                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom : null}
                                                label={'Datum od'}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap">

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                })}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : null}
                                                label={'Datum do'}
                                            ></DateField>
                                        </div>

                                    </div>
                                    <div>
                                        <button className={!this.state.reservation ? "blue-button mt25" : "blue-button-outline"} onClick={async () => {
                                            this.report()
                                        }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Izvjestaj</p>
                                        </button>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap">
                                            <Label>Proizvođač</Label>
                                            <Input type='text' value={this.state.filterManufacturer != null ? this.state.filterManufacturer : null} onChange={(e) => this.setState({ filterManufacturer: e.target.value }, () => {
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            {/* <Label>Artikal</Label>
                                            <Input type='text' value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : null} onChange={(e) => this.setState({ filterArticleFrom: e.target.value, articleError: null }, () => {
                                            })} /> */}

                                            <ArticleField
                                                label={'Artikal'}
                                                metoda={(value) => { this.metoda(value) }}
                                                dataModal={this.state.articlesSearch}
                                                onChange={(e) => this.setState({ filterArticleFrom: e, articleError: null })}
                                                value={this.state.filterArticleFrom}
                                                finishKey={'Enter'}
                                            // values={[...this.state.articlesSearch.map(item => {
                                            //     let obj = {
                                            //         name: item.name,
                                            //         value: item.code
                                            //     }
                                            //     return obj
                                            // })]}
                                            />

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                total={this.state.total}
                                loading={this.state.loading}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'price', label: 'PRODAJNA CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'fundPrice', label: 'FOND CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'discountPrice', label: 'RABAT CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'priceDifference', label: 'RAZLIKA U CIJENI'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'documentNumber', label: 'DOKUMENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'NAZIV KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammountDifference', label: 'RAZLIKA'.translate(this.props.lang), multilang: false, allowSort: true },

                                ]}
                                items={this.state.items.map(item => {
                                    return {
                                        ...item,
                                        artikal: item.items.article
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={[

                                ]}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/reports/return/fund/prices', title: 'Promet po fond cijenama' }}
                            ></ListBuilder>
                        </Col>
                        <Col lg='12'>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>SALDO</h6>
                                    <h4>
                                        {numberWithCommas(Math.round(this.state.saldo * 100) / 100)}
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>



                        </Col>
                    </Row>

                </Container>

            </div >
        )
    }
}

export default Page(StockPage);