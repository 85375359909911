import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';

import DateField from '../../form-builder/fields/date';
import PrintHelper from '../../components/printHelper';

import env from "react-dotenv";

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            withoutAmount: false
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Popisna lista</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button onClick={() => {
                            this.setState({ printHtml: null }, () => {
                                this._apiAsync('print-export-inventoryList', { ...params, action: 'print' }).then((res) => {
                                    console.log(res);

                                    if (res && res.file) {
                                        window.open(env.API_URL + `${res.file}`, '_blank');
                                        // window.location.href = env.API_URL + `${res.file}`;
                                    }
                                })
                            })

                        }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => {
                            this.setState({ printHtml: null }, () => {
                                this._apiAsync('print-export-inventoryList', { ...params, action: 'export' }).then((res) => {
                                    if (res && res) {

                                        if (res.success) {
                                            window.location.href = env.API_URL + `${res.file}`;
                                        }
                                    }
                                })
                            })

                        }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        {
                            this.state.printHtml ?
                                <PrintHelper html={this.state.printHtml} />
                                :
                                null
                        }
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap">
                                            <Label>Cijene</Label>
                                            <div className="check-box-wrap">
                                                <Checkbox checked={this.state.filterSellPrice != null ? this.state.filterSellPrice ? true : false : params.sellPrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterSellPrice: e.target.checked ? true : null }, () => {
                                                            this.updateParams('sellPrice', this.state.filterSellPrice)
                                                        })
                                                    }} label={'Prodajne'} />

                                                <Checkbox checked={this.state.filetPurchasePrice != null ? this.state.filetPurchasePrice ? true : false : params.purchasePrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filetPurchasePrice: e.target.checked ? true : null }, () => {
                                                            this.updateParams('purchasePrice', this.state.filetPurchasePrice)
                                                        })
                                                    }} label={'Nabavne'} />
                                                <Checkbox checked={this.state.filterBarCod != null ? this.state.filterBarCod ? true : false : params.barcode ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterBarCod: e.target.checked ? true : null }, () => {
                                                            this.updateParams('barcode', this.state.filterBarCod)
                                                        })
                                                    }} label={'Bar kod'} />
                                            </div>
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Label>Serije i rokovi</Label>
                                            <div className="check-box-wrap">
                                                <Checkbox checked={this.state.filterSeries != null ? this.state.filterSeries ? true : false : params.series ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterSeries: e.target.checked ? true : null }, () => {
                                                            this.updateParams('series', this.state.filterSeries)
                                                        })
                                                    }} label={'Sa serijama'} />

                                                <Checkbox checked={this.state.filterDeadlines != null ? this.state.filterDeadlines ? true : false : params.deadlines ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterDeadlines: e.target.checked ? true : null }, () => {
                                                            this.updateParams('deadlines', this.state.filterDeadlines)
                                                        })
                                                    }} label={'rokovima'} />

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className={!this.state.withoutAmount ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ withoutAmount: null }, () => this.updateParams('withoutAmount', null)) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>POPISNA LISTA SA KOLIČINAMA</p>
                                        </button>
                                    </div>

                                </div>
                                <div className='inventory-list-list-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters inventory-list-list" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : params.articleFrom} onChange={(e) => this.setState({ filterArticleFrom: e.target.value }, () => {
                                                this.updateParams('articleFrom', this.state.filterArticleFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleTo != null ? this.state.filterArticleTo : params.articleTo} onChange={(e) => this.setState({ filterArticleTo: e.target.value }, () => {
                                                this.updateParams('articleTo', this.state.filterArticleTo)
                                            })} />
                                        </div>


                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupFrom != null ? this.state.filterGroupFrom : params.groupFrom} onChange={(e) => this.setState({ filterGroupFrom: e.target.value }, () => {
                                                this.updateParams('groupFrom', this.state.filterGroupFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupTo != null ? this.state.filterGroupTo : params.groupTo} onChange={(e) => this.setState({ filterGroupTo: e.target.value }, () => {
                                                this.updateParams('groupTo', this.state.filterGroupTo)
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Proizvođač</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterManufacturer != null ? this.state.filterManufacturer : params.manufacturer} onChange={(e) => this.setState({ filterManufacturer: e.target.value }, () => {
                                                this.updateParams('manufacturer', this.state.filterManufacturer)
                                            })} />
                                        </div>
                                        {/* <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Datum</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterDate != null ? this.state.filterDate : params.date} onChange={(e) => this.setState({ filterDate: e.target.value }, () => {
                                                this.updateParams('date', this.state.filterDate)
                                            })} />
                                        </div> */}
                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Podgrupa</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterSubgroup != null ? this.state.filterSubgroup : params.subgroup} onChange={(e) => this.setState({ filterSubgroup: e.target.value }, () => {
                                                this.updateParams('subgroup', this.state.filterSubgroup)
                                            })} />
                                        </div>
                                        {/* <div className="clear-filters" style={{ top: 0, marginLeft: 25 }} onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterBusiness: '',
                                                filterTypeOfChange: -1,
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'type', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div> */}

                                    </div>
                                    <div>
                                        <button className={this.state.withoutAmount ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ withoutAmount: true }, () => this.updateParams('withoutAmount', true)) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>POPISNA LISTA BEZ KOLIČINA</p>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            {
                                this.state.withoutAmount ?
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        maxHeight={'48vh'}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'productCode', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'barcodes', label: 'BAR KOD'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 30 },
                                            { type: 'text', name: 'series', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'deadlines', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'buyPrice', label: 'NABAVNA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'sellPrice', label: 'PRODAJNA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        ]}
                                        items={this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                                barcodes: item.barcode && item.barcode.length ? item.barcode.join(', ') : '',
                                                series: item.series && item.series.length ? item.series.join(', ') : '',
                                                deadlines: item.expirationDates && item.expirationDates.length ? item.expirationDates.join(', ') : '',
                                            }
                                        })}
                                        rawItems={this.state.items}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={arrowDown} className="delete-icon" />,
                                                    onClick: (item) => {
                                                        if ((this.state.dropdownActive && this.state.dropdownActive._id != item._id) || !this.state.dropdownActive) {
                                                            this.setState({ dropdownActive: item })
                                                        } else {
                                                            this.setState({ dropdownActive: null })
                                                        }

                                                    },
                                                    dropdownShowCondition: (item) => {
                                                        if (this.state.dropdownActive && item._id == this.state.dropdownActive._id)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    condition: (item) => {
                                                        if (item.packer || item.collector)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    dropdownShow: <div className="drop-down-wrap">
                                                        <table>
                                                            <tr>
                                                                <th>SAKUPLJAČ</th>
                                                                <th>PAKER</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.collectorUsername}</td>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.packerUsername}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                },

                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        maxHeight={'48vh'}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'productCode', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'barcodes', label: 'BAR KOD'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 30 },
                                            { type: 'text', name: 'series', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'deadlines', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'currentQuantity', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'buyPrice', label: 'NABAVNA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'sellPrice', label: 'PRODAJNA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'sum', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                                        ]}
                                        items={this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                                barcodes: item.barcode && item.barcode.length ? item.barcode.join(', ') : '',
                                                amount: item.quantity - item.quantityReserved,
                                                series: item.series && item.series.length ? item.series.join(', ') : '',
                                                deadlines: item.expirationDates && item.expirationDates.length ? item.expirationDates.join(', ') : '',
                                                // sum: Math.round(((item.quantity - item.quantityReserved) * item.sellPrice) * 100) / 100
                                            }
                                        })}
                                        rawItems={this.state.items}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={arrowDown} className="delete-icon" />,
                                                    onClick: (item) => {
                                                        if ((this.state.dropdownActive && this.state.dropdownActive._id != item._id) || !this.state.dropdownActive) {
                                                            this.setState({ dropdownActive: item })
                                                        } else {
                                                            this.setState({ dropdownActive: null })
                                                        }

                                                    },
                                                    dropdownShowCondition: (item) => {
                                                        if (this.state.dropdownActive && item._id == this.state.dropdownActive._id)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    condition: (item) => {
                                                        if (item.packer || item.collector)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    dropdownShow: <div className="drop-down-wrap">
                                                        <table>
                                                            <tr>
                                                                <th>SAKUPLJAČ</th>
                                                                <th>PAKER</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.collectorUsername}</td>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.packerUsername}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                },
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ deleteModal: item })
                                                // },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                            }

                        </Col>
                        <Col lg={{ size: 4, offset: 8 }}>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno</h6>
                                    <h4>
                                        {
                                            this.state.stockSum ?
                                                numberWithCommas(this.state.stockSum)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>


                        </Col>

                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);