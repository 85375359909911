import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';
import DateField from '../../form-builder/fields/date';
import PrintHelper from '../../components/printHelper';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import env from "react-dotenv";
import moment from "moment";


function numberWithCommas(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}

function dynamicSort(property, sortOrder) {
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            reservation: false,
            filterSellPrice: true,
            orderItems: []
        }
    }
    get = () => {
        let params = this._getParams();
        // let reservation = params.reservation ? true : false;
        // this.setState({ reservation: reservation }, () => {
        //     this.updateParams('reservation', this.state.reservation)
        // })


        this.updateMultipleParams([
            { name: 'sellPrice', value: this.state.filterSellPrice },
            { name: 'purchasePrice', value: this.state.filterPurchasePrice },
            { name: 'barcode', value: this.state.filterBarCod },
            { name: 'document', value: this.state.filterDocument },
            { name: 'salesFrom', value: this.state.filterSalesFrom },
            { name: 'salesTo', value: this.state.filterSalesTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'articleTo', value: this.state.filterArticleTo },
            { name: 'groupFrom', value: this.state.filterGroupFrom },
            { name: 'groupTo', value: this.state.filterGroupTo },
            { name: 'manufacturer', value: this.state.filterManufacturer },
            { name: 'subgroup', value: this.state.filterSubgroup },
            { name: 'reservation', value: this.state.reservation ? true : null },
            { name: 'lastUpdate', value: Math.floor(new Date().getTime() / 1000) },
        ])


    }
    componentDidMount() {
        // this._onLoad();
        // this.get()

        if (typeof window != 'undefined' && localStorage.order) {
            let orderItems = JSON.parse(localStorage.order)
            if (orderItems.length) {
                this.setState({ orderItems }, () => this.checkOrderItemsAmounts())
            }
        }
        this.setDocumentNumber();
        this.checkParams()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (JSON.stringify(prevState.items) != JSON.stringify(this.state.items)) {
            this.checkOrderItemsAmounts()
        }


    }
    checkOrderItemsAmounts = () => {
        if (this.state.orderItems && this.state.items) {
            let items = this.state.items;
            for (let i = 0; i < items.length; i++) {
                let index = this.state.orderItems.findIndex(el => el.stockId == items[i]._id);
                if (index > -1) {
                    items[i].ammount = this.state.orderItems[index].ammount;
                } else {
                    items[i].ammount = '';
                }
            }
            this.setState({ items })

        }
    }
    printOrder = async () => {

        let printHtml = await this._apiAsync('print', { items: this.state.orderItems })
        if (printHtml && printHtml.printHtml) {
            
            // this.setState({ printHtml: printHtml.printHtml })
            window.open(env.API_URL + `${printHtml.printHtml}`, '_blank');
        }

    }
    export = async () => {
        let exportResult = await this._apiAsync('export', { items: this.state.orderItems })
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }

    checkParams = (get = false) => {
        let obj = {}
        let params = this._getParams()


        if (!this.state.filterSellPrice && params.sellPrice) {
            obj.filterSellPrice = params.sellPrice;
        }
        if (!this.state.filterPurchasePrice && params.purchasePrice) {
            obj.filterPurchasePrice = params.purchasePrice;
        }
        if (!this.state.filterBarCod && params.barcode) {
            obj.filterBarCod = params.barcode;
        }
        if (!this.state.filterDocument && params.document) {
            obj.filterDocument = params.document;
        }
        if (!this.state.filterSalesFrom && params.salesFrom) {
            obj.filterSalesFrom = params.salesFrom;
        }
        if (!this.state.filterSalesTo && params.salesTo) {
            obj.filterSalesTo = params.salesTo;
        }
        if (!this.state.filterArticleFrom && params.articleFrom) {
            obj.filterArticleFrom = params.articleFrom;
        }
        if (!this.state.filterArticleTo && params.articleTo) {
            obj.filterArticleTo = params.articleTo;
        }
        if (!this.state.filterGroupFrom && params.groupFrom) {
            obj.filterGroupFrom = params.groupFrom;
        }
        if (!this.state.filterGroupTo && params.groupTo) {
            obj.filterGroupTo = params.groupTo;
        }
        if (!this.state.filterManufacturer && params.manufacturer) {
            obj.filterManufacturer = params.manufacturer;
        }
        if (!this.state.filterSubgroup && params.subgroup) {
            obj.filterSubgroup = params.subgroup;
        }
        if (!this.state.reservation && params.reservation) {
            obj.reservation = true;
        }
        // if (Object.keys(obj).length !== 0) {

        //     this.setState({ ...obj }, () => {
        //         this.get()
        //         if (this.state.filterDocument) {
        //             this._apiAsync('get-output', { documentNumber: this.state.filterDocument }).then((res) => {
        //                 if (res && res.factureDate) {
        //                     this.setState({ filterDate: res.factureDate })
        //                 } else {
        //                     this.setState({ filterDate: '' })
        //                 }
        //             })
        //         } else {
        //             this.setState({ filterDate: '' })
        //         }
        //     })
        // }


    }

    setDocumentNumber() {
        this._apiAsync('checkDocumentNumber').then((response) => {
            this.setState({
                filterDocument: response.result.firstFreeNumber,
                filterDate: moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
            })

        })
    }



    clearOrder = () => {
        if (this.state.orderItems) {
            this.setState({ orderItems: [] }, () => {
                this.checkOrderItemsAmounts()
                if (typeof window != 'undefined' && localStorage.order) {
                    localStorage.removeItem('order')
                }
            })
        }
        this.setState({
            filterSellPrice: '',
            filterPurchasePrice: '',
            filterBarCod: '',
            filterDocument: '',
            filterDate: '',
            filterSalesFrom: '',
            filterSalesTo: '',
            filterArticleFrom: '',
            filterArticleTo: '',
            filterGroupFrom: '',
            filterGroupTo: '',
            filterManufacturer: '',
            filterSubgroup: '',
            reservation: false

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'sellPrice', value: null },
                    { name: 'purchasePrice', value: null },
                    { name: 'barcode', value: null },
                    { name: 'document', value: null },
                    { name: 'date', value: null },
                    { name: 'salesFrom', value: null },
                    { name: 'salesTo', value: null },
                    { name: 'articleFrom', value: null },
                    { name: 'articleTo', value: null },
                    { name: 'groupFrom', value: null },
                    { name: 'groupTo', value: null },
                    { name: 'manufacturer', value: null },
                    { name: 'subgroup', value: null },
                    { name: 'reservation', value: null },
                    { name: 'lastUpdate', value: null },
                ])
        })
    }

    updateOrderItems = (field, type) => {
        let sortOrder = this.state.sortOrder;

        if (sortOrder && sortOrder.field == field) {
            if (sortOrder.type == 1) {
                type = -1;
            } else {
                type = 1;
            }
        }
        let orderItems = this.state.orderItems.map(item => {
            return {
                ...item,
                ammount: Number(item.ammount)
            }
        });
        orderItems.sort(dynamicSort(field, type));
        this.setState({ orderItems, sortOrder: { field, type } }, () => {
            if (typeof window != 'undefined') {
                localStorage.setItem('order', JSON.stringify(this.state.orderItems))
            }
        })
    }

    deleteFromOrder = (item) => {
        this.setState({ deleteModal: null })
        let orderItems = this.state.orderItems;
        if (orderItems && orderItems.length) {
            let index = orderItems.findIndex(el => el.stockId == item.stockId)
            if (index > -1) {
                orderItems.splice(index, 1)
                this.setState({ orderItems }, () => {
                    this.checkOrderItemsAmounts()
                    if (typeof window != 'undefined') {
                        localStorage.setItem('order', JSON.stringify(this.state.orderItems))
                    }
                })
            }
        }


    }



    insertOrUpdate = (data) => {

        if (this.state.odred) {
            if (data) {
                data.id = this.state.odred
            }
            this._apiAsync('update', data).then((res) => {
                console.log(res);

            })
        } else {
            let obj ={
                documentNumber: this.state.filterDocument,
                date: this.state.filterDate,
                items: data

            }
            
            this._apiAsync('insert-new', obj).then((res) => {
                this.setState({
                    odred: res?.response?.id
                })
            })
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Narudžba</h1>
                    <div className="title-options-group">
                        <button onClick={() => {
                            this.setState({ printHtml: null }, () => {
                                this.printOrder()
                            })
                        }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => this.export()}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        <button onClick={() => this.clearOrder()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                        {
                            this.state.printHtml ?
                                <PrintHelper html={this.state.printHtml} />
                                :
                                null
                        }
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">

                            <div className='report-filters-wrap'>
                                <div className='report-filters-left'>
                                    <div className='first-row'>
                                        <div className="field-strap-wrap check-fields-box-wrap">
                                            <Label>Cijene</Label>
                                            <div className="check-box-wrap">
                                                <Checkbox checked={this.state.filterSellPrice}
                                                    onChange={(e) => {

                                                        this.setState({ filterSellPrice: e.target.checked ? true : null }, () => {
                                                        })
                                                    }} label={'Prodajne'} />

                                                <Checkbox checked={this.state.filterPurchasePrice}
                                                    onChange={(e) => {

                                                        this.setState({ filterPurchasePrice: e.target.checked ? true : null }, () => {
                                                        })
                                                    }} label={'Nabavne'} />
                                                <Checkbox checked={this.state.filterBarCod}
                                                    onChange={(e) => {

                                                        this.setState({ filterBarCod: e.target.checked ? true : null }, () => {
                                                        })
                                                    }} label={'Bar kod'} />
                                            </div>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Dokument</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterDocument} onChange={(e) => this.setState({ filterDocument: e.target.value }, () => {
                                                if (this.state.filterDocument) {
                                                    this._apiAsync('get-output', { documentNumber: this.state.filterDocument }).then((res) => {
                                                        if (res && res.factureDate) {
                                                            this.setState({ filterDate: res.factureDate })
                                                        } else {
                                                            this.setState({ filterDate: '' })
                                                        }
                                                    })
                                                } else {
                                                    this.setState({ filterDate: '' })
                                                }

                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>

                                            <Label>Datum</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterDate} disabled={true} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterSalesFrom: value }, () => {
                                                })}
                                                value={this.state.filterSalesFrom}
                                                label={'Prodaja od'}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterSalesTo: value }, () => {
                                                })}
                                                value={this.state.filterSalesTo}
                                                label={'Prodaja do'}
                                            ></DateField>
                                        </div>
                                    </div>
                                    <div className='second-row'>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleFrom} onChange={(e) => this.setState({ filterArticleFrom: e.target.value }, () => {
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleTo} onChange={(e) => this.setState({ filterArticleTo: e.target.value }, () => {
                                            })} />
                                        </div>


                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupFrom} onChange={(e) => this.setState({ filterGroupFrom: e.target.value }, () => {
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupTo} onChange={(e) => this.setState({ filterGroupTo: e.target.value }, () => {
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Proizvođač</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterManufacturer} onChange={(e) => this.setState({ filterManufacturer: e.target.value }, () => {
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Podgrupa</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterSubgroup} onChange={(e) => this.setState({ filterSubgroup: e.target.value }, () => {
                                            })} />
                                        </div>
                                    </div>


                                </div>
                                <div className='report-filters-right'>
                                    <div>
                                        <button className={!this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ reservation: false }, () => this.get()) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>LAGER</p>
                                        </button>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ reservation: true }, () => this.get()) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>REZERVACIJA</p>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col lg="8" style={{ paddingLeft: 0, paddingRight: 20, marginTop: 25 }} id="left-table">
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'productCode', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'sellPrice', label: 'VPC'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'quantity', label: 'LAGER'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'out', label: 'IZLAZ'.translate(this.props.lang), multilang: false, allowSort: !this.state.filterSalesFrom && !this.state.filterSalesTo && !this.state.filterDocument ? true : false },
                                    { type: 'text', name: 'order', label: 'NARUDŽBA'.translate(this.props.lang), multilang: false, allowSort: false },

                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                        quantity: item.quantity - item.quantityReserved,

                                        order: <div>
                                            <Input id={`input-${item._id}`} type='text'
                                                onKeyUp={(e) => {
                                                    if (e.key == 'Enter') {
                                                        let value = item.ammount;
                                                        let orderItems = this.state.orderItems;
                                                        let index = orderItems.findIndex(el => el.stockId == item._id);
                                                        if (value && Number(value) > 0) {
                                                            if (index > -1) {
                                                                orderItems[index].ammount = value;
                                                            } else {
                                                                let obj = {
                                                                    productCode: item.productCode,
                                                                    productName: item.productName,
                                                                    price: item.sellPrice,
                                                                    stockId: item._id,
                                                                    ammount: Number(value),
                                                                }
                                                                orderItems.push(obj);
                                                            }

                                                        } else {
                                                            if (index > -1) {
                                                                orderItems.splice(index, 1);
                                                            }
                                                        }
                                                        console.log(this.state.orderItems, 'orderItems');
                                                        this.setState({ orderItems }, () => {
                                                            console.log(this.state.filterDocument,  this.state.filterDate);
                                                            this.insertOrUpdate(this.state.orderItems)

                                                            // if (typeof window != 'undefined') {
                                                            //     localStorage.setItem('order', JSON.stringify(this.state.orderItems))
                                                            // }
                                                        })
                                                        if (typeof window != 'undefined') {
                                                            document.getElementById(`input-${item._id}`).blur()
                                                            let index = this.state.items.findIndex(el => el._id == item._id);
                                                            let nextIndex;
                                                            if (index > -1) {
                                                                nextIndex = index + 1;
                                                            }
                                                            if (nextIndex > -1 && this.state.items[nextIndex] && this.state.items[nextIndex]._id) {
                                                                document.getElementById(`input-${this.state.items[nextIndex]._id}`).focus()
                                                            }

                                                        }
                                                    }
                                                }}
                                                value={item.ammount}
                                                onChange={(e) => {
                                                    let items = this.state.items;
                                                    if (items && items[idx]) {
                                                        items[idx].ammount = e.target.value;
                                                    }
                                                    this.setState({ items })
                                                }} />
                                        </div>
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg="4" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <div style={{ maxHeight: document.getElementById('left-table') && document.getElementById('left-table').scrollHeight > 600 ? (document.getElementById('left-table').scrollHeight - 77) : 'unset', overflow: 'hidden', overflowY: 'auto' }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loadingOrder}
                                    total={this.state.total}
                                    hidePagination={true}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'productCode', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOL'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.orderItems.map((item, idx) => {
                                        return {
                                            ...item,


                                        }
                                    })}
                                    rawItems={this.state.orderItems}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => {
                                                    this.setState({ deleteModal: item })

                                                },
                                            }
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateOrderItems}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </Col>
                        <Col lg={{ size: 4, offset: 8 }}>
                            <div className='blue-box-info-text-wrap'>
                                <div className='blue-box-info-text'>
                                    <h6>Ukupno</h6>
                                    <h4>
                                        {
                                            this.state.orderItems.length != 0 ?

                                                numberWithCommas(this.state.orderItems.reduce((sum, current) => {
                                                    let res;
                                                    res = sum + Number(current.price) * Number(current.ammount);
                                                    return res
                                                }, 0))
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>

                        </Col>

                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this.deleteFromOrder(this.state.deleteModal)
                        }} >
                        Da li želite obrisati narudžbu za artikal <strong>{this.state.deleteModal ? this.state.deleteModal.productName : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);