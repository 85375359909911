
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import exportIcon from '../../assets/export.svg';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import ErrorModal from '../../components/errorModal';
import env from 'react-dotenv';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Analysis extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            loading: false,
            items: [],
            form: { checkTypeOfChange: 'iz', checkTotal: true, checkClientCode: true },
            total: [],
            place: [],
            listBuilderFields: [],
            listBuilderFieldsMonth: [],
            sumByMonths: [],
            ammountByMonths: [],
            ammountList: false

        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();

            this._apiAsync('get-reports', { ...this.state.form, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }) }).then((response) => {
                this.setState({ items: response.items, total: response.total, checkTotal: true, checkClientCode: true })
                // this.setState({ total: response.total })
            })

        }
    }

    print = () => {
        this._apiAsync('get-reports-print', this.state.form).then((response) => {
            if (response.message !== 'done') {
                window.open(env.API_URL + response.message)
            }
        })
    }

    render() {
        let params = this._getParams();

        let items = this.state.items;

        let obj = {}
        if (items && true) {
            obj = {
                total0: this.state.sumByMonths[0] && !this.state.ammountList ?
                    numberWithCommas(Number(this.state.sumByMonths[0]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[0]) : 0,
                total1: this.state.sumByMonths[1] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[1]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[1]) : 0,
                total2: this.state.sumByMonths[2] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[2]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[2]) : 0,
                total3: this.state.sumByMonths[3] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[3]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[3]) : 0,
                total4: this.state.sumByMonths[4] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[4]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[4]) : 0,
                total5: this.state.sumByMonths[5] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[5]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[5]) : 0,
                total6: this.state.sumByMonths[6] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[6]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[6]) : 0,
                total7: this.state.sumByMonths[7] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[7]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[7]) : 0,
                total8: this.state.sumByMonths[8] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[1]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[8]) : 0,
                total9: this.state.sumByMonths[9] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[9]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[9]) : 0,
                total10: this.state.sumByMonths[10] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[10]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[10]) : 0,
                total11: this.state.sumByMonths[11] && !this.state.ammountList ? numberWithCommas(Number(this.state.sumByMonths[11]).toFixed(2)) : this.state.ammountList ? Number(this.state.ammountByMonths[11]) : 0
            };

            items = [...items, obj]
        } else {
            items = [...items]
        }

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Analiza prometa</h1>
                    <div className="title-options-group">
                        <button onClick={() => {
                            this.print()
                        }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article', 'checkProduct', 'checkGroup', 'checkRegion', 'checkPlace', 'checkSubGroup', 'checkKind', 'checkManufacturer', 'checkClient', 'checkBusiness', 'checkBusinessUnit', 'checkClientCode', 'checkTotal', 'checkTotalPerMonth', 'clientCode']}
                                fieldTracker={async (name, value) => {

                                    if (name === 'clientCode' && value) {
                                        const clients = await this._apiAsync('get-clients', { filter: value })
                                        if (clients && clients.items) {
                                            this.setState({ clients: clients && clients.items }, async () => {
                                                const clientObject = this.state.clients.find(item => Number(item.code) === Number(value))
                                                this.setState({ selectedClient: clientObject })
                                                if (clientObject) {
                                                    this.remoteOnChange('clientName', clientObject.name)
                                                    const checkBusinessUnitForClient = await this._apiAsync('check-client-exists-in-business-units', { clientId: clientObject._id });
                                                    this.setState({ readOnlyBusiness: checkBusinessUnitForClient.status !== 200 })
                                                } else {
                                                    this.remoteOnChange('clientName', '')
                                                }
                                            })
                                        }
                                    }

                                    let checkFilters = this.registerGetValue('checkFilters') ? this.registerGetValue('checkFilters') : [];

                                    if (name === 'checkProduct') {
                                        if (value) {
                                            checkFilters.push('Artikal')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'article', label: 'Šifra artikala'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true })
                                            listBuilderFields.push({ type: 'text', name: 'articleName', label: 'Artikal'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Artikal');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Šifra artikala')
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 2)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkGroup') {
                                        if (value) {
                                            checkFilters.push('Grupa')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'group', label: 'Grupa'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Grupa');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Grupa')
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }
                                    }
                                    if (name === 'checkRegion') {
                                        if (value) {
                                            checkFilters.push('Regija')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'region', label: 'Regija'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Regija');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Regija')
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkPlace') {
                                        if (value) {
                                            checkFilters.push('Mjesto')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'place', label: 'Mjesto'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Mjesto');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Mjesto')
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkSubGroup') {
                                        if (value) {
                                            checkFilters.push('Podgrupa')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'subGroup', label: 'Podgrupa'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Podgrupa');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Podgrupa')
                                                // console.log(val);
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkKind') {
                                        if (value) {
                                            checkFilters.push('Vrsta')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'kind', label: 'Vrsta'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Vrsta');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Vrsta')
                                                // console.log(val);
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkManufacturer') {
                                        if (value) {
                                            checkFilters.push('Proizvođač')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'manufacturerName', label: 'Proizvodjac'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Proizvođač');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Proizvodjac')
                                                // console.log(val);
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 1)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkClient') {
                                        if (value) {
                                            checkFilters.push('Komitent')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'clientCode', label: 'Šifra komitenta'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true })
                                            listBuilderFields.push({ type: 'text', name: 'clientName', label: 'Komitent'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Komitent');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Šifra komitenta')
                                                // console.log(val);
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 2)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }
                                    if (name === 'checkBusiness') {
                                        if (value) {
                                            checkFilters.push('Poslovna')
                                            let listBuilderFields = this.state.listBuilderFields;
                                            listBuilderFields.push({ type: 'text', name: 'businessCode', label: 'Šifra poslovne'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true })
                                            listBuilderFields.push({ type: 'text', name: 'businessName', label: 'Poslovna'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFields
                                            })
                                        } else {
                                            let index = checkFilters.findIndex(el => el == 'Poslovna');
                                            if (index > -1) {
                                                checkFilters.splice(index, 1)
                                                let listBuilderFields = this.state.listBuilderFields;
                                                let val = listBuilderFields.findIndex(item => item.label == 'Šifra poslovne')
                                                // console.log(val);
                                                if (listBuilderFields[val]) {
                                                    listBuilderFields.splice(val, 2)
                                                }
                                                this.setState({
                                                    listBuilderFields
                                                })
                                            }
                                        }

                                    }

                                    this.remoteOnChange('checkFilters', checkFilters)

                                    if (name === 'checkBusinessUnit' && value) {
                                        this.remoteOnChange('checkClientCode', false)

                                    }

                                    if (name === 'checkClientCode' && value) {
                                        this.remoteOnChange('checkBusinessUnit', false)

                                    }

                                    if (name === 'checkTotal' && value) {
                                        this.remoteOnChange('checkTotalPerMonth', false)
                                        this.setState({
                                            listBuilderFieldsMonth: []
                                        })
                                    }

                                    if (name === 'checkTotalPerMonth' && value) {
                                        this.remoteOnChange('checkTotal', false)
                                        if (value) {
                                            let listBuilderFieldsMonth = this.state.listBuilderFieldsMonth;
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total0', label: 'Januar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total1', label: 'Februar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total2', label: 'Mart'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total3', label: 'April'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total4', label: 'Maj'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total5', label: 'Jun'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total6', label: 'Jul'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total7', label: 'Avgust'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total8', label: 'Septembar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total9', label: 'Oktobar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total10', label: 'Novembar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            listBuilderFieldsMonth.push({ type: 'text', name: 'total11', label: 'Decembar'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false })
                                            this.setState({
                                                listBuilderFieldsMonth
                                            })

                                        } else {
                                            this.setState({
                                                listBuilderFieldsMonth: []
                                            })
                                        }
                                    }
                                }}

                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data, typeButton) => {
                                    console.log(data, typeButton)
                                    this.setState({
                                        form: data
                                    })
                                    if (typeButton) {
                                        data.typeButton = typeButton
                                    }
                                    if (data.typeButton == 'kolicina' && data.typeButton == 'nabavniIznos') {
                                        this.setState({
                                            ammount: true
                                        })
                                    }
                                    // console.log(data)
                                    if (data.checkFilters && data.checkFilters.length) {
                                        this._apiAsync('get-reports', data).then((response) => {
                                            console.log(response);
                                            this.setState({ items: response.items, total: response.total, totalAmount: response.totalAmmount, sum: response.sum, sumByMonths: response.sumByMonths, ammountByMonths: response.ammountByMonths, loading: false })
                                        })
                                        return "open-modal"
                                    } else {
                                        this.setState({
                                            error: "Potrebno je izabrati bar jedan filter gore u lijevom uglu",

                                        })
                                    }


                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix input-mb-small",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 3, sm: 10, xs: 10 },
                                                        className: "checkbox-list",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkProduct',
                                                                                label: 'Artikal',

                                                                            },
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkGroup',
                                                                                label: 'Grupa',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkRegion',
                                                                                label: 'Regija',

                                                                            },
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkPlace',
                                                                                label: 'Mjesto',
                                                                                validate: []

                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkSubGroup',
                                                                                label: 'Podgrupa',

                                                                            },
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkKind',
                                                                                label: 'Vrsta',

                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkManufacturer',
                                                                                label: 'Proizvođač',

                                                                            },
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkClient',
                                                                                label: 'Komitent',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [

                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkBusiness',
                                                                                label: 'Poslovna',
                                                                                validate: []

                                                                            },
                                                                        ]
                                                                    },

                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'textarea',
                                                                                name: 'checkFilters',
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkClientCode',
                                                                                label: 'Komitent',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'checkBusinessUnit',
                                                                                label: 'Poslovna',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { marginTop: 10 },
                                                                        children: [
                                                                            {
                                                                                type: 'select',
                                                                                arrow: true,
                                                                                name: 'checkTypeOfChange',
                                                                                label: 'Vrsta promjene',
                                                                                values: [{ value: 'iz', name: 'Izlaz' }, { value: 'ul', name: 'Ulaz' }, { value: 'ni', name: 'Nivelacija' }, { value: 'nr', name: 'Naturalni rabat' }, { value: 'pd', name: 'Povrat dobavljaču' }, { value: 'pk', name: 'Povrat kupcu' }],
                                                                                validate: []
                                                                            },

                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'region',
                                                                                next: 'place',
                                                                                label: 'Regija',
                                                                                values: [{ value: 'RS', name: 'RS' }, { value: 'FBiH', name: 'FBIH' }, { value: 'BD', name: 'BD' }],
                                                                                asyncValidation: true,

                                                                                validate: [

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'clientCode',
                                                                                next: 'business',
                                                                                label: 'Komitent',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clients = await this._apiAsync('get-clients', { filter: value })
                                                                                                if (clients && clients.items) {
                                                                                                    this.setState({ clients: clients && clients.items }, () => {
                                                                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('clientName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'place',
                                                                                next: 'clientCode',
                                                                                label: 'Mjesto',
                                                                                asyncValidation: true,
                                                                                values: [...this.state.place.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const place = await this._apiAsync('get-place', { filter: value })
                                                                                                console.log(place.items)
                                                                                                if (place && place.items) {
                                                                                                    this.setState({ place: place && place.items }, () => {
                                                                                                        if (this.state.place.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('placeName', this.state.place.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('placeName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                ]

                                                                            },
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'business',
                                                                                next: 'groups',
                                                                                label: 'Poslovna',
                                                                                readOnly: this.state.readOnlyBusiness,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                values: [...this.state.businessUnits.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                validate: [
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clientObject = this.state.clients.find(el => el.code == this.registerGetValue('clientCode'))
                                                                                                const businessUnits = await this._apiAsync('get-business', { filter: value, query: { client: clientObject._id } })
                                                                                                if (businessUnits && businessUnits.items) {
                                                                                                    this.setState({ businessUnits: businessUnits && businessUnits.items }, () => {
                                                                                                        if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('businessName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('businessName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'groups',
                                                                                next: 'subGroups',
                                                                                label: 'Grupe',
                                                                                finishField: true,
                                                                                asyncValidation: true,

                                                                                validate: [

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'products',
                                                                                next: 'dateFrom',
                                                                                label: 'Artikli',
                                                                                finishField: true,
                                                                                asyncValidation: true,

                                                                                validate: [

                                                                                ]
                                                                            },


                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'subGroups',
                                                                                next: 'products',
                                                                                label: 'Podgrupe',
                                                                                asyncValidation: true,
                                                                                validate: [


                                                                                ]
                                                                            },

                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [

                                                                            {
                                                                                type: 'date',
                                                                                name: 'dateFrom',
                                                                                next: 'dateTo',
                                                                                label: 'Datum od',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                                    validateDate('Datum nije validan')
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [

                                                                            {
                                                                                type: 'date',
                                                                                name: 'dateTo',
                                                                                next: 'articleFrom',
                                                                                label: 'Datum do',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                                    validateDate('Datum nije validan')
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 3, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 4, sm: 12, xs: 12 },
                                                                        style: { paddingTop: 10, paddingLeft: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'checkTotal',
                                                                                        label: 'Totali',

                                                                                    },


                                                                                ]
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 8, sm: 12, xs: 12 },
                                                                        style: { padding: 0, paddingRight: 20 },
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                                children: [

                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'checkTotalPerMonth',
                                                                                        label: 'Totali po mjesecima',

                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    },

                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        ammount: true,
                                                                                        ammountList: false,
                                                                                        mustShowQuantity: false
                                                                                    })
                                                                                    this.submit('prodajniIznos')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>PRODJAJNI IZNOS</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        ammount: false,
                                                                                        ammountList: true,
                                                                                        mustShowQuantity: true
                                                                                    })
                                                                                    this.submit('kolicina')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>KOLIČINA</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        ammount: true,
                                                                                        ammountList: false,
                                                                                        mustShowQuantity: false
                                                                                    })
                                                                                    this.submit('nabavniIznos')

                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>NABAVNI IZNOS</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 10 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        ...this.state.listBuilderFields,
                                        ...this.state.listBuilderFieldsMonth,
                                        { type: 'text', name: 'quantity', label: 'KOLIČINA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'IZNOS'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true, hideColumn: !this.state.ammount },

                                    ]}
                                    items={items ? items.map((item, idx) => {
                                        console.log(item)
                                        let months = {}
                                        if (item && item.months && item.months.length) {
                                            for (let i = 0; i < item.months.length; i++) {
                                                let month = new Date(item.months[i].from * 1000).getMonth()
                                                if (this.state.ammountList) {
                                                    months[`total${month}`] = item.months[i]._quantity.toFixed(2)
                                                } else {
                                                    months[`total${month}`] = item.months[i]._total.toFixed(2)
                                                    months[`_quantity${month}`] = item.months[i]._quantity
                                                }

                                            }
                                        }

                                        return {
                                            ...item,
                                            client: item && item.client && item.client.code ? item.client.code : '',
                                            clientName: item && item.client && item.client.code ? item.client.name : '',
                                            manufacturerName: item && item.manufacturer && item.manufacturer.name ? item.manufacturer.name : '',
                                            businessCode: item && item.business && item.business.code ? item.business.code : '',
                                            businessName: item && item.business && item.business.name ? item.business.name : '',
                                            quantity: item && item._quantity && (!this.state.ammountList || this.state.mustShowQuantity) ? item._quantity : '',
                                            total: item && item._total ? item._total.toFixed(2) : '',
                                            // totalAll: item && item.total && item.discountValue ? (item.total - item.discountValue).toFixed(2) : item.total.toFixed(2)
                                            ...months
                                        }
                                    }) : null}

                                    // items={this.state.outputItems}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null, form: { checkTypeOfChange: 'iz', checkTotal: true, checkClientCode: true }, })}
                        error={this.state.error}
                    />
                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Količina</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalAmount).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.sum && this.state.sum != 0 ?
                                                numberWithCommas(parseFloat(this.state.sum).toFixed(2))
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(Analysis);

