
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import Checkbox from '../../form-builder/fields/checkbox';
import Select from '../../form-builder/fields/select';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import DateField from '../../form-builder/fields/date';
import env from "react-dotenv";
import { parseJSON } from '../../helpers/json';


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class TradeBook extends RouteComponent {
    constructor(props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this)
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            outputItems: [],
            manufacturers: [],
            total: [],

            filterWareHouse: '',
            filterTypeOfChange: '',
            pom: false,
            pom1: true,
            odgovor: [],
            btn_1: false,
            btn_2: false,
            btn_3: false,
            btn_4: false,
            btn_5: false,
            filterSellPrice: true,
            filterEstimation: false,
            filterTraffic: true,
            filterNotBooked: false,
            filterBooked: false,
            filterAll: true,
            filterPurchasePrice: false,
            filterArticleService: false,
        }
    }

    componentDidMount() {
        this._onLoad();
        this.setState({ filterDateFrom: '01.01.' + this.props.selectedYear })
        this.setState({ filterDateTo: '31.12.' + this.props.selectedYear })
        this.setState({ filterDocumentFrom: "" })
        this.setState({ filterDocumentTo: "" })
        document.getElementById('tradeBook-1').focus()

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
            this.getItems()

        }
    }



    getItems = async () => {
        this.setState({ pom: true }, async () => {

            if (this.state.btn_1) {
                if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
                    let podaciAll = await this._apiAsync('getDataAll', this._getParams())
                    console.log(podaciAll, 'pod');
                    this.setState({ outputItems: podaciAll.items, total: podaciAll.total, debt: podaciAll.debt, discountSum: podaciAll.discountSum, divorce: podaciAll.divorce }, () => {
                        this.setState({ pom: false })

                    })

                } else {
                    let podaci = await this._apiAsync('getData', this._getParams())
                    this.setState({ outputItems: podaci.items, total: podaci.total, debt: podaci.debt, discountSum: podaci.discountSum, divorce: podaci.divorce }, () => {
                        this.setState({ pom: false })

                    })
                }
            }

            if (this.state.btn_2) {
                if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
                    let podaciAll = await this._apiAsync('getDataDonosAll', this._getParams())
                    this.setState({ outputItems: podaciAll.items, total: podaciAll.total }, () => {
                        this.setState({ pom: false })
                    })

                } else {
                    let podaci = await this._apiAsync('getDataDonos', this._getParams())
                    this.setState({ outputItems: podaci.odgovor.items, total: podaci.total }, () => {
                        this.setState({ pom: false })
                    })
                }
            }

            if (this.state.btn_3) { }


            if (this.state.btn_4) {
                if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
                    let podaciAll = await this._apiAsync('getDataAll', this._getParams())
                    this.setState({ outputItems: podaciAll.items, total: podaciAll.total }, () => {
                        this.setState({ pom: false })
                    })

                } else {
                    let podaci = await this._apiAsync('getData', this._getParams())
                    this.setState({ outputItems: podaci.items, total: podaci.total }, () => {
                        this.setState({ pom: false })
                    })
                }
            }

            if (this.state.btn_5) {
                if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
                    let podaciAll = await this._apiAsync('getDataRazCijenaAll', this._getParams())
                    this.setState({ outputItems: podaciAll.items, total: podaciAll.total, sumSalesAmount: podaciAll.sumSalesAmount, sumAmountDiscount: podaciAll.sumAmountDiscount, sumDifferencePrice: podaciAll.sumDifferencePrice, sumPurchaseAmount: podaciAll.sumPurchaseAmount, sumSalesAmountVat: podaciAll.sumSalesAmountVat, sumOutputVAT: podaciAll.sumOutputVAT, sumSalesAmountVat: podaciAll.sumSalesAmountVat }, () => {
                        this.setState({ pom: false })
                    })

                } else {
                    let podaci = await this._apiAsync('getDataRazCijena', this._getParams())
                    this.setState({ outputItems: podaci.items, total: podaci.total, sumSalesAmount: podaci.sumSalesAmount, sumAmountDiscount: podaci.sumAmountDiscount, sumDifferencePrice: podaci.sumDifferencePrice, sumPurchaseAmount: podaci.sumPurchaseAmount, sumSalesAmountVat: podaci.sumSalesAmountVat, sumOutputVAT: podaci.sumOutputVAT, sumSalesAmountVat: podaci.sumSalesAmountVat }, () => {
                        this.setState({ pom: false })
                    })
                }
            }



        })

    }

    provjeraParametara = () => {
        let p1 = this._getParams()

        this.updateMultipleParams([
            { name: 'sellPrice', value: this.state.filterSellPrice },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'documentFrom', value: this.state.filterDocumentFrom },
            { name: 'documentTo', value: this.state.filterDocumentTo },
            { name: 'user', value: this.state.filterUser },
            { name: 'estimation', value: this.state.filterEstimation },
            { name: 'traffic', value: this.state.filterTraffic },
            { name: 'notBooked', value: this.state.filterNotBooked },
            { name: 'change', value: this.state.filterTypeOfChange },
            { name: 'booked', value: this.state.filterBooked },
            { name: 'all', value: this.state.filterAll },
            { name: 'purchasePrice', value: this.state.filterPurchasePrice },
            { name: 'articleservice', value: this.state.filterArticleService },
            { name: 'page', value: '0' }
        ])

        this.setState({ pom2: true }, () => {
            let p2 = this._getParams()
            if ((JSON.stringify(p1) === JSON.stringify(p2))) {
                this.getItems()
            }
        })
    }

    stampa = async () => {
        let naslov = this.state.btn_1 ? 'Trgovacka knjiga  VP' : this.state.btn_2 ? 'Trgovacka knjiga VP donos' : this.state.btn_3 ? 'Usluge' : this.state.btn_4 ? 'Totali po dokumentima' : ''
        let api = ''
        if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
            if (this.state.btn_1 || this.state.btn_4) {
                api = 'getDataAll'
            }else if(this.state.btn_2){
                api = 'getDataDonosAll'
            }else if(this.state.btn_5){
                api = 'getDataRazCijenaAll'
            }
        }else{
            if (this.state.btn_1 || this.state.btn_4) {
                api = 'getData'
            }else if(this.state.btn_2){
                api = 'getDataDonos'
            }else if(this.state.btn_5){
                api = 'getDataRazCijena'
            }
        }
        if (this.state.filterDateFrom && this.state.filterDateTo) {
            let dateFrom = this.props.getTsFromDateString(this.state.filterDateFrom)
            let dateTo = this.props.getTsFromDateString(this.state.filterDateTo)

            if (!dateFrom) {
                this.setState({ error: 'Početni datum je neispravan' })
            } else if (!dateTo) {
                this.setState({ error: 'Krajnji datum je neispravan' })
            } else if (dateTo > dateFrom) {
                this.setState({
                    loading: true
                }, () => {
                    this._apiAsync(api, {
                        'sellPrice': this.state.filterSellPrice,
                        'dateFrom': this.state.filterDateFrom,
                        'dateTo': this.state.filterDateTo,
                        'documentFrom': this.state.filterDocumentFrom,
                        'documentTo': this.state.filterDocumentTo,
                        'user': this.state.filterUser,
                        'estimation': this.state.filterEstimation,
                        'traffic': this.state.filterTraffic,
                        'notBooked': this.state.filterNotBooked,
                        'change': this.state.filterTypeOfChange,
                        'booked': this.state.filterBooked,
                        'all': this.state.filterAll,
                        'purchasePrice': this.state.filterPurchasePrice,
                        'articleservice': this.state.filterArticleService,
                        'print': true,
                        'title': naslov

                    }).then((response) => {
                        if (typeof window != 'undefined') {
                            window.open(env.API_URL + response.link)
                            this.setState({ loading: false })
                        }

                    })

                })
            } else {
                this.setState({ error: 'Početni datum mora biti manji od krajnjeg datuma' })
            }

        } else {
            this.setState({ error: 'Datum je obavezan' })
        }


    }

    handleEnter = (event) => {

        if (event.key.toLowerCase() == 'enter') {

            const currentField = Number(document.activeElement.id.split('-')[1])
            const nextField = currentField + 1
            document.getElementById(document.activeElement.id.split('-')[0] + '-' + nextField.toString()).focus()
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Trgovačka knjiga</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button onClick={this.stampa}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row className="panel-backgound" style={{ paddingBottom: 20 }}>
                        <Col lg="3">
                            <div className="analysis-col-1">
                                <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                    <div className="field-strap-wrap">
                                        <div className="check-box-wrap">
                                            <Row className="checkbox-list">
                                                <Col lg="12">
                                                    {/* <Checkbox name="call" checked={this.state.filterAll != null ? this.state.filterAll ? true : false : params.all ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterAll: e.target.checked ? true : null }, () => {
                                                            })
                                                        }} label={'Sve'} /> */}
                                                    <Checkbox name="call" checked={this.state.filterAll}
                                                        onChange={(e) => {
                                                            this.setState({ filterAll: !this.state.filterAll }, () => {
                                                            })
                                                        }} label={'Sve'} />
                                                </Col>
                                                <Col lg="12">
                                                    {/* <Checkbox name="csellprice" checked={this.state.filterSellPrice != null ? this.state.filterSellPrice ? true : false : params.sellPrice ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterSellPrice: e.target.checked ? true : false }, () => {

                                                            })
                                                        }} label={'Prodajne'} /> */}
                                                    <Checkbox name="csellprice" checked={this.state.filterSellPrice}
                                                        onChange={(e) => {
                                                            this.setState({ filterSellPrice: !this.state.filterSellPrice }, () => {

                                                            })
                                                        }} label={'Prodajne'} />
                                                </Col>
                                                <Col lg="12">
                                                    {/* <Checkbox name="cpurchaseprice" checked={this.state.filterPurchasePrice != null ? this.state.filterPurchasePrice ? true : false : params.purchasePrice ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterPurchasePrice: e.target.checked ? true : false }, () => {

                                                            })
                                                        }} label={'Nabavne'} /> */}
                                                    <Checkbox name="cpurchaseprice" checked={this.state.filterPurchasePrice}
                                                        onChange={(e) => {
                                                            this.setState({ filterPurchasePrice: !this.state.filterPurchasePrice }, () => {

                                                            })
                                                        }} label={'Nabavne'} />
                                                </Col>

                                                <Col lg="12">
                                                    {/* <Checkbox
                                                        name="cbooked"
                                                        checked={this.state.filterBooked != null ? this.state.filterBooked ? true : false : params.booked ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterBooked: e.target.checked ? true : null }, () => {

                                                            })
                                                        }}
                                                        label={'Proknjiženo'}
                                                    /> */}
                                                    <Checkbox
                                                        name="cbooked"
                                                        checked={this.state.filterBooked}
                                                        onChange={(e) => {
                                                            this.setState({ filterBooked: !this.state.filterBooked }, () => {

                                                            })
                                                        }}
                                                        label={'Proknjiženo'}
                                                    />
                                                </Col>
                                                <Col lg="12">
                                                    {/* <Checkbox
                                                        name="cnotbooked"
                                                        checked={this.state.filterNotBooked != null ? this.state.filterNotBooked ? true : false : params.notBooked ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterNotBooked: e.target.checked ? true : null }, () => {

                                                            })
                                                        }}
                                                        label={'Nije proknjiženo'}
                                                    /> */}

                                                    <Checkbox
                                                        name="cnotbooked"
                                                        checked={this.state.filterNotBooked}
                                                        onChange={(e) => {
                                                            this.setState({ filterNotBooked: !this.state.filterNotBooked }, () => {

                                                            })
                                                        }}
                                                        label={'Nije proknjiženo'}
                                                    />
                                                </Col>

                                                <Col lg="12">
                                                    {/* <Checkbox name="ctraffic" checked={this.state.filterTraffic != null ? this.state.filterTraffic ? true : false : params.traffic ? true : false}
                                                        onChange={(e) => {
                                                            this.setState({ filterTraffic: e.target.checked ? true : null }, () => {


                                                            })
                                                        }} label={'Promet'} /> */}
                                                    <Checkbox name="ctraffic" checked={this.state.filterTraffic}
                                                        onChange={(e) => {
                                                            this.setState({ filterTraffic: !this.state.filterTraffic }, () => {


                                                            })
                                                        }} label={'Promet'} />
                                                </Col>
                                                <Col lg="12">
                                                    {/* <Checkbox name="cestimation" checked={this.state.filterEstimation != null ? this.state.filterEstimation ? true : false : params.estimation ? true : false}
                                                        onChange={(e) => {

                                                            this.setState({ filterEstimation: e.target.checked ? true : null }, () => {
                                                                // this.updateParams('estimation', this.state.filterEstimation)
                                                                // this.setState({ filterTraffic: !this.state.filterEstimation })

                                                            })
                                                        }} label={'Predračun'} /> */}
                                                    <Checkbox name="cestimation" checked={this.state.filterEstimation}
                                                        onChange={(e) => {

                                                            this.setState({ filterEstimation: !this.state.filterEstimation }, () => {
                                                                // this.updateParams('estimation', this.state.filterEstimation)
                                                                // this.setState({ filterTraffic: !this.state.filterEstimation })

                                                            })
                                                        }} label={'Predračun'} />
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5">
                            <div className="analysis-col-2">
                                <form>
                                    <Row>
                                        {/* <Col lg="3">
                                            <div className="field-strap-wrap">
                                                <Label>Skladište</Label>
                                                <Input type='text' name='skladiste' readOnly value={this.state.filterChangeType != null ? this.state.filterChangeType : params.changeType} onChange={(e) => this.setState({ filterChangeType: e.target.value }, () => {
                                                    // this.updateParams('changeType', this.state.filterChangeType)
                                                })} />
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv skladište</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivSkladista'
                                                    readOnly
                                                    value={this.state.filterWareHouse != null ? this.state.filterChangeType : params.changeType}
                                                    onChange={(e) => this.setState({ filterWareHouse: e.target.value }, () => {
                                                        // this.updateParams('changeType', this.state.filterChangeType)
                                                    })}

                                                />
                                            </div>
                                        </Col> */}

                                        {/* <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv vrste promjene</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivVP'
                                                    readOnly
                                                    value={this.state.filterChangeType}

                                                />
                                            </div>
                                        </Col> */}


                                        {/* <Col lg="3">
                                            <div className="field-strap-wrap">
                                                <Label>Korisnik</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-2'
                                                    name='korisnik'
                                                    value={this.state.filterUser != null ? this.state.filterUser : params.user}
                                                    onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                        // this.updateParams('user', this.state.filterUser)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv korisnika</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivKorisnika'
                                                    readOnly
                                                    value={this.state.filterUser}
                                                />
                                            </div>
                                        </Col> */}
                                        <Col lg="5">
                                            <div className="field-strap-wrap">
                                                <Label>Dokument od</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-3'
                                                    name='dokumentOd'
                                                    value={this.state.filterDocumentFrom != null ? this.state.filterDocumentFrom : params.documentFrom}
                                                    onKeyDown={this.handleEnter}
                                                    onChange={(e) => this.setState({ filterDocumentFrom: e.target.value }, () => {
                                                        // this.updateParams('documentFrom', this.state.filterDocumentFrom)
                                                    })}

                                                />

                                            </div>
                                        </Col>

                                        <Col lg="5">
                                            <div className="field-strap-wrap">
                                                <Label>Dokument do</Label>
                                                <Input
                                                    type='text'
                                                    name='dokumentDo'
                                                    id='tradeBook-4'
                                                    value={this.state.filterDocumentTo != null ? this.state.filterDocumentTo : params.documentTo}
                                                    // onKeyDown={this.handleEnter} 
                                                    onChange={(e) => this.setState({ filterDocumentTo: e.target.value }, () => {
                                                        // this.updateParams('documentTo', this.state.filterDocumentTo)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="5">
                                            {/* <div className="field-strap-wrap">
                                            <Label>Datum od</Label>
                                            <Input type='text' name='datumOd' value={this.state.filterDateFrom != null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                // this.updateParams('dateFrom', this.state.filterDateFrom)
                                            })} />
                                        </div> */}
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-150'}
                                                    onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                        //this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })}
                                                    value={this.state.filterDateFrom}
                                                    label={'Datum od'}
                                                    id='tradeBook-5'
                                                    onKeyDown={this.handleEnter}
                                                ></DateField>
                                            </div>
                                        </Col>
                                        <Col lg="5">
                                            {/* <div className="field-strap-wrap">
                                            <Label>Datum do</Label>
                                            <Input type='text' name='datumDo' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                // this.updateParams('dateTo', this.state.filterDateTo)
                                            })} />
                                        </div> */}
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-150'}
                                                    onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                        //this.updateParams('dateTo', this.state.filterDateTo)
                                                    })}
                                                    value={this.state.filterDateTo}
                                                    label={'Datum do'}
                                                    id='tradeBook-6'
                                                    onKeyDown={this.handleEnter}
                                                ></DateField>
                                            </div>
                                        </Col>
                                        <Col lg="5">
                                            <div className="field-strap-wrap select-clasic">

                                                <Label>Vrsta promjene</Label>
                                                <Input
                                                    type='select'
                                                    id='tradeBook-1'
                                                    value={this.state.filterTypeOfChange != null ? this.state.filterTypeOfChange : params.change}
                                                    onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                                        // this.updateParams('change', this.state.filterTypeOfChange)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                >
                                                    <option value={-1}></option>
                                                    <option value={'ul'}>Ulaz</option>
                                                    <option value={'iz'}>Izlaz</option>
                                                    <option value={'nr'}>Naturalni rabat</option>
                                                    <option value={'pd'}>Povrat dobavljaču</option>
                                                    <option value={'pk'}>Povrat kupcu</option>
                                                    <option value={'ni'}>Nivelacija</option>
                                                    <option value={'in'}>Inventura</option>
                                                    <option value={'rz'}>Rezervacija</option>
                                                    <option value={'pr'}>Predračun</option>
                                                    <option value={'mn'}>Manjak</option>
                                                    <option value={'ot'}>Otpis</option>


                                                </Input>
                                            </div>
                                        </Col>

                                        <Col lg="5">
                                            <div className="field-strap-wrap select-clasic">
                                                {/* 
                                                <Label>Proizvod/Usluga</Label>
                                                <Input
                                                    type='select'
                                                    id='tradeBook-7'
                                                    value={this.state.filterArticleService != null ? this.state.filterArticleService : params.articleservice}
                                                    onChange={(e) => this.setState({ filterArticleService: e.target.value }, () => {
                                                        // this.updateParams('articleservice', this.state.filterArticleService)
                                                    })}>
                                                    <option value={-1}></option>
                                                    <option value={'usluga'}>Usluga</option>
                                                    <option value={'artikal'}>Artikal</option>
                                                    <option value={'roba'}>Roba</option>
                                                    <option value={'proizvod'}>Proizvod</option>
                                                    onKeyDown={this.handleEnter}
                                                </Input> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="analysis-buttons-container">
                                <div className="analsis-buttons-left">
                                    {/* <h6>TOTALI</h6> */}
                                    <button id='vel1' className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {
                                        this.setState({ btn_1: true })
                                        this.setState({ btn_2: false })
                                        this.setState({ btn_3: false })
                                        this.setState({ btn_4: false })
                                        this.setState({ btn_5: false })
                                        this.provjeraParametara()

                                        // this.getItems()

                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Trgovačka knjiga VP</p>
                                    </button>
                                    <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {

                                        this.setState({ btn_1: false })
                                        this.setState({ btn_2: true })
                                        this.setState({ btn_3: false })
                                        this.setState({ btn_4: false })
                                        this.setState({ btn_5: false })

                                        this.provjeraParametara()


                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Trgovačka knjiga VP donos</p>
                                    </button>
                                    {/* <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {

                                        this.setState({ btn_1: false })
                                        this.setState({ btn_2: false })
                                        this.setState({ btn_3: true })
                                        this.setState({ btn_4: false })
                                        this.setState({ btn_5: false })

                                        this.provjeraParametara()


                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Knjiga usluga</p>
                                    </button> */}
                                    <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {

                                        this.setState({ btn_1: false })
                                        this.setState({ btn_2: false })
                                        this.setState({ btn_3: false })
                                        this.setState({ btn_4: true })
                                        this.setState({ btn_5: false })

                                        this.provjeraParametara()



                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Total po dokumentima</p>
                                    </button>
                                    <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {

                                        this.setState({ btn_1: false })
                                        this.setState({ btn_2: false })
                                        this.setState({ btn_3: false })
                                        this.setState({ btn_4: false })
                                        this.setState({ btn_5: true })

                                        this.provjeraParametara()

                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Total sa razlikom u cijeni</p>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 35 }}>

                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.pom}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[

                                        { type: 'text', name: 'documentDate', label: 'Datum dokumenta'.translate(this.props.lang), multilang: false, allowSort: false },
                                        this.state.btn_5 ? { type: 'text', name: 'nabavnaVrijednost', label: 'Nabavna vrijednost'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true } : { type: 'text', name: 'opis', label: 'Opis'.translate(this.props.lang), multilang: false, allowSort: false },
                                        this.state.btn_5 ? { type: 'text', name: 'razlikaUcijeni', label: 'Razlika u cijeni'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true } : { type: 'text', name: 'clientName', label: 'Komitent'.translate(this.props.lang), multilang: false, allowSort: false },
                                        this.state.btn_5 ? { type: 'text', name: 'izlazniPDV', label: 'Izlazni PDV'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true } : { type: 'text', name: 'zaduzenje', label: this.state.btn_4 ? 'Vrednost ulaza' : 'Zaduženje'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true },
                                        this.state.btn_5 ? { type: 'text', name: 'rabat', label: 'Rabat'.translate(this.props.lang), numberWithCommas: true, multilang: false, allowSort: false } : { type: 'text', name: 'razduzenje', label: this.state.btn_4 ? 'Vrednost izlaza' : 'Razduženje'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true },
                                        // { type: 'text', name: 'ulaz', label: 'Ukupno ulaz'.translate(this.props.lang), multilang: false, allowSort: true },
                                        this.state.btn_5 ? { type: 'text', name: 'prodajnaVrijednost', label: 'Prodajna vrijednost'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true } : { type: 'text', name: 'discount', label: 'Rabat'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true },
                                        this.state.btn_5 ? { type: 'text', name: 'prodajnaVrijednostRabat', label: 'Prodajna vrijednost sa rabatom'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true } : '',
                                        this.state.btn_5 ? { type: 'text', name: 'vrsta', label: 'Vrsta'.translate(this.props.lang), multilang: false, allowSort: false } : '',

                                    ]}

                                    items={this.state.outputItems}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>



                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            {!this.state.btn_5 ?
                                <div className="info-colored">
                                    <div className="col-info">
                                        <p>Zaduženje</p>
                                        <span>
                                            {
                                                this.state.debt && this.state.debt != 0 ?
                                                    numberWithCommas(parseFloat(this.state.debt).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Razduženje</p>
                                        <span>
                                            {
                                                this.state.divorce && this.state.divorce != 0 ?
                                                    numberWithCommas(parseFloat(this.state.divorce).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Rabat</p>
                                        <span>
                                            {
                                                this.state.discountSum && this.state.discountSum != 0 ?
                                                    numberWithCommas(parseFloat(this.state.discountSum).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="info-colored">
                                    <div className="col-info">
                                        <p>Nabavna vrijednost</p>
                                        <span>
                                            {
                                                this.state.sumPurchaseAmount && this.state.sumPurchaseAmount != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumPurchaseAmount).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Razlika u cijeni</p>
                                        <span>
                                            {
                                                this.state.sumDifferencePrice && this.state.sumDifferencePrice != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumDifferencePrice).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Izlazni PDV</p>
                                        <span>
                                            {
                                                this.state.sumOutputVAT && this.state.sumOutputVAT != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumOutputVAT).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Rabat</p>
                                        <span>
                                            {
                                                this.state.sumAmountDiscount && this.state.sumAmountDiscount != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumAmountDiscount).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Prodajna</p>
                                        <span>
                                            {
                                                this.state.sumSalesAmount && this.state.sumSalesAmount != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumSalesAmount).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                    <div className="col-info">
                                        <p>Prodajna sa rabatom</p>
                                        <span>
                                            {
                                                this.state.sumSalesAmountVat && this.state.sumSalesAmountVat != 0 ?
                                                    numberWithCommas(parseFloat(this.state.sumSalesAmountVat).toFixed(2))
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(TradeBook);

